<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Manpower Client Report Management</p>
                <p class="text-title mb-0">You do not have permission to view all manpower client's reports</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Manday Deployment</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense clearable hide-details label="Year" class="my-3" :items="years" :menu-props="{top: false, offsetY: true}" v-model="filters.year"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-autocomplete dense clearable hide-details label="Clients" class="my-3" :items="clients" v-model="filters.client"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" order="last">
                                <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> View
                                </v-btn>
                                <v-btn small class="elevation-0 mt-2" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_download">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="elevation-0 mb-2" :ripple="false" @click="openDownloadDialog">Download Report</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.client"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.project"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.deployed"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.cost"></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-if="pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.num_pages" :total-visible="7" v-model="filters.page"></v-pagination>
                </v-col>
            </v-row>
        </template>
        <download-dialog ref="downloadDialog" @downloaded="handleDownloaded"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
    </div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import { makeYearList } from '@/utils/dates'
import ManpowerDownloadDialog from './ManpowerDownloadDialog.vue'
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue'

export default {
    name: 'MandayClientReport',
    components: {
        simpleActionDialog: SimpleActionDialog,
        downloadDialog: ManpowerDownloadDialog,
    },
    data() {
        return {
            loading: true,
            filters: {
                year: '',
                client: '',
                page: 1
            },
            object: {},
            permissions: {
                can_list: false,
                can_download: false
            },
            items: [],
            clients: [],
            headers: [],
            pagination: {}
        }
    },
    computed: {
        years() {
            return makeYearList(2021)
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        },
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            var filters = copyObject(this.filters)
            this.$store.dispatch('epanel/report/getReportManpowerClient', filters).then((response) => {
                this.clients = response.data.clients
                this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        search: function() {
            this.filters.page = 1
            this.get()
        },
        reset: function() {
            this.filters = { year: '', month: '' }
        },
        getStyle: function() {
            return {"min-height": "200px"}
        },
        handleDownloaded: function(){
            this.loadingDialog.open()
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download){
                this.downloadDialog.updateObject({ year: this.filters.year, client: this.filters.client })
                this.downloadDialog.open()
            }
        },
    }
}

</script>